// Avoid `console` errors in browsers that lack a console.
(function() {
  var method;
  var noop = function () {};
  var methods = [
    'assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error',
    'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
    'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
    'timeline', 'timelineEnd', 'timeStamp', 'trace', 'warn'
  ];
  var length = methods.length;
  var console = (window.console = window.console || {});

  while (length--) {
    method = methods[length];

    // Only stub undefined methods.
    if (!console[method]) {
      console[method] = noop;
    }
  }
}());

// Place any jQuery/helper plugins in here.

//const jquery = require("jquery");
//window.$ = window.jQuery = jquery;

// import 'bootstrap/js/dist/alert';
// import 'bootstrap/js/dist/button';
// import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
// import 'bootstrap/js/dist/dropdown';
import Modal from 'bootstrap/js/dist/modal';
// import Offcanvas from 'bootstrap/js/dist/offcanvas';
// import 'bootstrap/js/dist/popover';
// import 'bootstrap/js/dist/scrollspy';
// import 'bootstrap/js/dist/tab';
// import 'bootstrap/js/dist/toast';
// import 'bootstrap/js/dist/tooltip';

window._Modal = Modal;

/*
function check_undefined_class() {
  "use strict";

  //get all unique CSS classes defined in the main document
  let allClasses = Array.from(document.querySelectorAll('*'))
    .map(n => Array.from(n.classList))
    .reduce((all, a) => all ? all.concat(a) : a)
    .reduce((all, i) => all.add(i), new Set());

  //load contents of all CSS stylesheets applied to the document
  let loadStyleSheets = Array.from(document.styleSheets)
    .map(s => {
      if (s.href) {
        return fetch(s.href)
          .then(r => r.text())
          .catch(e => {
            console.warn('Coudn\'t load ' + s.href + ' - skipping');
            return "";
          });
      }

      return s.ownerNode.innerText
    });

  Promise.all(loadStyleSheets).then(s => {
    let text = s.reduce((all, s) => all + s);

    //get a list of all CSS classes that are not mentioned in the stylesheets
    let undefinedClasses = Array.from(allClasses)
      .filter(c => {
        var rgx = new RegExp(escapeRegExp('.' + c) + '[^_a-zA-Z0-9-]');

        return !rgx.test(text);
      });

    if(undefinedClasses.length) {
      console.log('List of ' + undefinedClasses.length + ' undefined CSS classes: ', undefinedClasses);
    } else {
      console.log('All CSS classes are defined!');
    }
  });

  function escapeRegExp(str) {
    return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
  }

}
window.check_undefined_class = check_undefined_class;
check_undefined_class();
*/
